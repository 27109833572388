<template>
  <div>
    <div class="reserved-area-page">
      <div class="container">
        <div class="d-xl-flex">
          <div class="reserved-area-page-column">
            <reservedAreaMenu/>
          </div>
          <div class="reserved-area-page-column">
            <div class="reserved-area-invoice-list-wrapper">
              <div class="reserved-area-invoice-list">
                <div class="reserved-area-form-group-wrapper" v-for="(item,index) in invoices">
                  <p class="section-title reserved-area-form-title">ANO {{ index }}</p>
                  <div class="reserved-area-form-group">
                    <div class="reserved-area-invoice-item" v-for="(val,key) in item">
                      <div class="d-flex justify-content-between align-items-center">
                        <p class="reserved-area-invoice-item-title">{{ val.title }}</p>
                        <div class="reserved-area-invoice-item-icon">
                          {{val.date}}
                        </div>
                      </div >
                    </div>
                  </div>
                </div>
              </div>
              <div class="reserved-area-invoice-list-btn-wrapper">
                <button type="button" class="reserved-area-invoice-list-btn" @click="toggleInvoices">Ver Mais…</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import eyeIcon from "@/components/svg/eye.vue";
import axios from "axios";

export default {
  name: "invoices",
  data() {
    return {
      invoices: []
    }
  },
  components: {
    reservedAreaMenu,
    eyeIcon
  },
  metaInfo () {
    return {
      title: this.$root.translations.definicoes,
    }
  },
  methods: {
    toggleInvoices(e) {
      let target = e.currentTarget;
      let wrapper = target.closest('.reserved-area-invoice-list-wrapper');

      if (!wrapper.classList.contains('active')) {
        wrapper.classList.add('active');
      }
    }
  },
  created() {
    this.$root.pageTitle = this.$root.translations.definicoes;
    let authKey = window.$cookies.get("authKey");
    axios
        .get(process.env.VUE_APP_API_URL + 'customer/invoices', {
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.invoices = response.data.model
        })
        .catch((e) => {
          this.errors.push(e);
          this.showMessage = true;
          this.formResponseMessage = 'An error occurred.';
        });
  }
}
</script>